<template>
  <div v-if="listData.length || mineListData.length">
    <div class="wrp">
      <div class="list">
        <template
          class="list-item"
          v-for="(item, index) in mineListData"
          :key="index"
        >
          <item-card :item="item" @handleClickItem="handleClickItem" />
        </template>
      </div>
      <div
        class="split-line"
        v-if="listData.length || mineListData.length"
      ></div>
      <van-list
        v-model:loading="loading"
        :finished="finished"
        :finished-text="'没有更多了'"
        :immediate-check="false"
        @load="loadMoreFc"
      >
        <div class="list">
          <template
            class="list-item"
            v-for="(item, index) in listData"
            :key="index"
          >
            <item-card :item="item" @handleClickItem="handleClickItem" />
          </template>
        </div>
      </van-list>
    </div>
  </div>
  <div class="empty" v-else>
    <div class="empty-title">暂无作品</div>
  </div>
</template>

<script setup>
import { defineProps, ref, computed } from "vue";
import { useRoute, useRouter } from "vue-router";
import { List as VanList } from "vant";
import axios from "axios";
import { DuButton } from "@frontend/dumpling";
import ItemCard from "./components/ItemCard.vue";

import { getQuery } from "@/utils/url";
import NftApi from "@/apis";
import { REQUEST_OK } from "@/constant/network";
import { storeToRefs } from "pinia";
import { useUsersStore } from "@/store/user";
import { invokeAppByYYB } from "@/utils/mob";
import { isProd } from "@/utils/env-related";

const route = useRoute();
const router = useRouter();
const userStore = useUsersStore();

const { userInfo } = storeToRefs(userStore);

const props = defineProps({
  test: {
    type: Object,
  },
});

const listData = ref([]);
const mineListData = ref([]);
const loading = ref(false);
const loadingFailed = ref(false);
const finished = ref(false);

const loadMoreFc = () => {
  console.log("loadingFailed.value", loadingFailed.value);
  if (loadingFailed.value) return;
  initListData();
};

const handleClickItem = (itemData) => {
  router.push(`/token-detail?tokenId=${itemData.id}`);
};

const initListData = async ({ isReset } = { isReset: false }) => {
  const { tokenId } = getQuery();

  loading.value = true;
  // const { data } = await NftApi.getSubjectList({
  const { code, data } = await NftApi.getOwnerList({
    tokenId,
    limit: 10,
    offset: isReset ? 0 : listData.value.length,
  });

  loading.value = false;

  if (code !== REQUEST_OK) {
    loadingFailed.value = true;
    return;
  }

  const tempData = data.list;

  if (isReset) {
    listData.value = tempData;
  } else {
    listData.value = listData.value.concat(tempData);
  }

  const hasMore = data.hasMore;
  if (hasMore) {
    finished.value = false;
  } else {
    finished.value = true;
  }

  document.title = `${data.total}位拥有者同时拥有该作品`;
};

const initMineData = async () => {
  const { tokenId } = getQuery();

  loading.value = true;
  // const { data } = await NftApi.getSubjectList({
  const { code, data } = await NftApi.getOwnerList({
    tokenId,
    limit: 100,
    offset: 0,
    onlyMine: true,
  });

  loading.value = false;

  if (code !== REQUEST_OK) {
    loadingFailed.value = true;
    return;
  }

  mineListData.value = data.list;
};

const initDesc = async () => {
  let res = "";
  if (isProd) {
    res = await axios.get(
      "https://config-cdn.qiandaoapp.com/congress/common-config.json"
    );
  } else {
    res = await axios.get(
      "https://dev-config-cdn.qiandaoapp.com/congress/common-config.json"
    );
  }
  console.log("initDesc res===", res);
};

// watch(
//   () => props.currentArtType,
//   () => {
//     initData({ isReset: true });
//   },
//   {
//     immediate: false,
//   }
// );

initListData();
initMineData();
initDesc();
</script>

<style lang="scss" scoped>
.wrp {
  margin-top: 30rpx;
  padding: 0 20rpx;
}
.list {
}
.split-line {
  width: 100%;
  margin: 48rpx 0;
  border-top: 2rpx dashed rgba(0, 0, 0, 0.12);
}
.empty {
  margin-top: 32rpx;
  display: flex;
  width: 100%;
  text-align: center;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  &-title {
    color: rgba(0, 0, 0, 0.4);
    text-align: center;
    font-size: 28rpx;
    font-style: normal;
    font-weight: 400;
    line-height: 44rpx; /* 157.143% */
  }
  &-btn {
    margin-top: 24rpx;
    width: 100%;
    text-align: center;
  }
}
</style>
