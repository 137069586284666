<template>
  <div class="item" @click="handleClickItem(item)">
    <img
      class="item-avatar"
      :src="item.owner.avatarUrl + '!mfit_w480_h480_jpg'"
      alt=""
    />
    <div class="item-content">
      <div class="item-content-top">
        <span class="item-content-top-name">{{ item.owner.displayName }}</span>
        <span v-if="item.isMotherToken" class="item-content-top-badge"
          >发布者</span
        >
      </div>
      <div class="item-content-bottom">
        <span>{{ formateTime(item.ownTime) }}</span
        >&nbsp; <span>拥有该作品</span>&nbsp;
        <span>#{{ item.tokenIndex }}</span>
      </div>
    </div>
  </div>
</template>

<script setup>
import { defineProps, defineEmits } from "vue";
import dayjs from "dayjs";

const props = defineProps({
  item: {
    type: Object,
  },
});

const emits = defineEmits(["handleClickItem"]);

const formateTime = (time) => {
  return `${dayjs(+time).format("YYYY-MM-DD")}`;
};

const handleClickItem = (item) => {
  emits("handleClickItem", item);
};
</script>

<style lang="scss" scoped>
.item {
  display: flex;
  margin-bottom: 48rpx;
  &-avatar {
    width: 80rpx;
    height: 80rpx;
    flex-shrink: 0;
    object-fit: cover;
    border-radius: 100%;
    border: 2rpx solid rgba(0, 0, 0, 0.06);
  }
  &-content {
    margin-left: 16rpx;
    &-top {
      display: flex;
      align-items: center;
      &-name {
        color: #000;
        font-size: 32rpx;
        font-style: normal;
        font-weight: 500;
        line-height: 48rpx; /* 150% */
        letter-spacing: 0.32rpx;
      }
      &-badge {
        margin-left: 8rpx;
        display: flex;
        padding: 4rpx;
        align-items: flex-start;
        color: #7247dc;
        font-size: 20rpx;
        font-style: normal;
        font-weight: 500;
        line-height: 22rpx; /* 110% */
        border-radius: 4rpx;
        background: linear-gradient(
          151deg,
          rgba(43, 26, 192, 0.05) 0%,
          rgba(114, 71, 220, 0.05) 100%
        );
      }
    }
    &-bottom {
      color: rgba(0, 0, 0, 0.5);
      font-size: 24rpx;
      font-style: normal;
      font-weight: 400;
      line-height: 36rpx; /* 150% */
    }
  }
}
</style>
